#startscreen-app-root #startscreen-editor-root .DialogActions {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 20px;
}

#startscreen-app-root #startscreen-editor-root .DialogButtonsList {
  display: flex;
  flex-direction: column;
  justify-content: right;
  margin-top: 20px;
}

#startscreen-app-root #startscreen-editor-root .DialogButtonsList button {
  margin-bottom: 20px;
}
