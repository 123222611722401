#startscreen-app-root #startscreen-editor-root .dropdown {
  z-index: 99999999999999999;
}

#startscreen-app-root #startscreen-editor-root #--ss-divider {
  border-right-width: 0.5px;
  border-right-color: #f3f4f6;
}

#startscreen-app-root #startscreen-editor-root #--ss-loomPlayer {
  width: 400px;
  /* height: 260px; */
  object-fit: contain;
}

#startscreen-app-root #startscreen-editor-root .PlaygroundEditorTheme__ol1 {
  list-style: decimal;
}

#startscreen-app-root #startscreen-editor-root .PlaygroundEditorTheme__ul {
  list-style: disc;
}

#startscreen-app-root #startscreen-editor-root .floating-text-format-popup {
  padding: 0;
}

#startscreen-app-root #startscreen-editor-root .PlaygroundEditorTheme__h1 span {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

#startscreen-app-root #startscreen-editor-root .component-picker-menu {
  bottom: 275px;
  position: relative;
  height: 200px;
  left: 10px;
}

#startscreen-app-root #startscreen-editor-root .editor-shell .editor-container {
  background: transparent;
  position: relative;
  cursor: text;
  display: block;
  /* border-radius: 10px; */
  min-height: 0px;
}

#startscreen-app-root #startscreen-editor-root .editor-container {
  min-height: 0px;
  /* margin-top: 20px;
  margin-bottom: 20px; */
  /* margin: 20px auto 20px auto; */
  border-radius: 2px;
  /* max-width: 675px; */
  color: #111827;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
}

#startscreen-app-root #startscreen-editor-root .editor-inner {
  background: #fff;
  overflow-y: auto;
}

#startscreen-app-root #startscreen-editor-root .editor-inner.shadow-top {
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
}

#startscreen-app-root #startscreen-editor-root .editor-inner.shadow-bottom {
  box-shadow: inset 0 -3px 6px 0px rgb(0 0 0 / 0.03);
}

#startscreen-app-root #startscreen-editor-root .editor-inner.shadow-bottom.shadow-top {
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05), inset 0 -1px 2px 2px rgb(0 0 0 / 0.03)
}

#startscreen-app-root #startscreen-editor-root .shadow-top {
  color: #111827;
  min-height: 0px;
  resize: none;
  /* overflow-y: scroll; */
  font-size: 15px;
  position: relative;
  tab-size: 1;
  outline: 0;
  tab-size: 1;
  overflow-y: auto;
}

#startscreen-app-root #startscreen-editor-root .editor-input.editable {
  padding: 15px 10px;
}

#startscreen-app-root #startscreen-editor-root .editor-placeholder {
  color: #9ca3af;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}
#startscreen-app-root #startscreen-editor-root .toolbar {
  display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
  /* box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  z-index: 50, */
}
