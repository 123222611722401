@import 'https://fonts.googleapis.com/css?family=Reenie+Beanie';

/* body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

#startscreen-app-root #startscreen-editor-root header {
  max-width: 580px;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
}

#startscreen-app-root #startscreen-editor-root header a {
  max-width: 220px;
  margin: 20px 0 0 0;
  display: block;
}

#startscreen-app-root #startscreen-editor-root header img {
  display: block;
  height: 100%;
  width: 100%;
}

#startscreen-app-root #startscreen-editor-root header h1 {
  text-align: left;
  color: #333;
  display: inline-block;
  margin: 20px 0 0 0;
}

#startscreen-app-root #startscreen-editor-root .editor-shell {
  margin: 0;
  height: 100%;
  border-radius: 2px;
  max-width: 1100px;
  color: #000;
  position: relative;
  line-height: 1.7;
  font-weight: 400;
}

#startscreen-app-root #startscreen-editor-root .editor-shell .editor-container {
  margin: 20px 0;
  background: #fff;
  min-height: 350px;
  position: relative;
  cursor: text;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-container.tree-view {
  border-radius: 0;
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-container.plain-text {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

#startscreen-app-root #startscreen-editor-root .editor-scroller {
  min-height: 150px;
  border: 0;
  resize: none;
  cursor: text;
  display: block;
  position: relative;
  outline: 0;
  overflow: auto;
  /* resize: vertical; */
}

#startscreen-app-root #startscreen-editor-root .editor {
  height: 100%;
  position: relative;
}

#startscreen-app-root #startscreen-editor-root .test-recorder-output {
  margin: 20px auto 20px auto;
  width: 100%;
}

#startscreen-app-root #startscreen-editor-root pre {
  line-height: 1.1;
  background: #222;
  color: #fff;
  margin: 0;
  padding: 10px;
  font-size: 12px;
  overflow: auto;
  max-height: 180px;
}

#startscreen-app-root #startscreen-editor-root .tree-view-output {
  display: block;
  background: #222;
  color: #fff;
  padding: 0;
  font-size: 12px;
  margin: 1px auto 10px auto;
  max-height: 250px;
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#startscreen-app-root #startscreen-editor-root pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

#startscreen-app-root #startscreen-editor-root pre::-webkit-scrollbar-thumb {
  background: #999;
}

#startscreen-app-root #startscreen-editor-root .editor-dev-button {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  font-size: 12px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
  background-color: #444;
}

#startscreen-app-root #startscreen-editor-root .editor-dev-button::after {
  content: '';
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  display: block;
  background-size: contain;
  filter: invert(1);
}

#startscreen-app-root #startscreen-editor-root .editor-dev-button:hover {
  background-color: #555;
}

#startscreen-app-root #startscreen-editor-root .editor-dev-button.active {
  background-color: rgb(233, 35, 35);
}

#startscreen-app-root #startscreen-editor-root .test-recorder-toolbar {
  display: flex;
}

#startscreen-app-root #startscreen-editor-root .test-recorder-button {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  font-size: 10px;
  padding: 6px 6px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.4);
  background-color: #222;
  transition: box-shadow 50ms ease-out;
}

#startscreen-app-root #startscreen-editor-root .test-recorder-button:active {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
}

#startscreen-app-root
  #startscreen-editor-root
  .test-recorder-button
  + .test-recorder-button {
  margin-left: 4px;
}

#startscreen-app-root #startscreen-editor-root .test-recorder-button::after {
  content: '';
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  display: block;
  background-size: contain;
  filter: invert(1);
}

#startscreen-app-root #startscreen-editor-root #options-button {
  position: fixed;
  left: 20px;
  bottom: 20px;
}

#startscreen-app-root #startscreen-editor-root #test-recorder-button {
  position: fixed;
  left: 70px;
  bottom: 20px;
}

#startscreen-app-root #startscreen-editor-root #paste-log-button {
  position: fixed;
  left: 120px;
  bottom: 20px;
}

#startscreen-app-root #startscreen-editor-root #options-button::after {
  background-image: url('../public/icons/gear.svg');
}

#startscreen-app-root #startscreen-editor-root #test-recorder-button::after {
  background-image: url('../public/icons/journal-code.svg');
}

#startscreen-app-root #startscreen-editor-root #paste-log-button::after {
  background-image: url('../public/icons/clipboard.svg');
}

#startscreen-app-root #startscreen-editor-root #test-recorder-button-snapshot {
  margin-right: auto;
}

#startscreen-app-root
  #startscreen-editor-root
  #test-recorder-button-snapshot::after {
  background-image: url('../public/icons/camera.svg');
}

#startscreen-app-root #startscreen-editor-root #test-recorder-button-copy::after {
  background-image: url('../public/icons/clipboard.svg');
}

#startscreen-app-root
  #startscreen-editor-root
  #test-recorder-button-download::after {
  background-image: url('../public/icons/download.svg');
}

#startscreen-app-root #startscreen-editor-root .typeahead-popover {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin-top: 25px;
}

#startscreen-app-root #startscreen-editor-root .typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
}

#startscreen-app-root
  #startscreen-editor-root
  .typeahead-popover
  ul::-webkit-scrollbar {
  display: none;
}

#startscreen-app-root #startscreen-editor-root .typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#startscreen-app-root #startscreen-editor-root .typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

#startscreen-app-root #startscreen-editor-root .typeahead-popover ul li.selected {
  background: #eee;
}

#startscreen-app-root #startscreen-editor-root .typeahead-popover li {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
}

#startscreen-app-root #startscreen-editor-root .typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

#startscreen-app-root #startscreen-editor-root .typeahead-popover li:first-child {
  border-radius: 8px 8px 0px 0px;
}

#startscreen-app-root #startscreen-editor-root .typeahead-popover li:last-child {
  border-radius: 0px 0px 8px 8px;
}

#startscreen-app-root #startscreen-editor-root .typeahead-popover li:hover {
  background-color: #eee;
}

#startscreen-app-root #startscreen-editor-root .typeahead-popover li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

#startscreen-app-root #startscreen-editor-root .typeahead-popover li .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

#startscreen-app-root #startscreen-editor-root .component-picker-menu {
  width: 200px;
}

#startscreen-app-root #startscreen-editor-root .mentions-menu {
  width: 250px;
}

#startscreen-app-root #startscreen-editor-root .auto-embed-menu {
  width: 150px;
}

#startscreen-app-root #startscreen-editor-root .emoji-menu {
  width: 200px;
}

#startscreen-app-root #startscreen-editor-root i.palette {
  background-image: url('../public/icons/palette.svg');
}

#startscreen-app-root #startscreen-editor-root i.bucket {
  background-image: url('../public/icons/paint-bucket.svg');
}

#startscreen-app-root #startscreen-editor-root i.bold {
  background-image: url('../public/icons/type-bold.svg');
}

#startscreen-app-root #startscreen-editor-root i.italic {
  background-image: url('../public/icons/type-italic.svg');
}

#startscreen-app-root #startscreen-editor-root i.clear {
  background-image: url('../public/icons/trash.svg');
}

#startscreen-app-root #startscreen-editor-root i.code {
  background-image: url('../public/icons/code.svg');
}

#startscreen-app-root #startscreen-editor-root i.underline {
  background-image: url('../public/icons/type-underline.svg');
}

#startscreen-app-root #startscreen-editor-root i.strikethrough {
  background-image: url('../public/icons/type-strikethrough.svg');
}

#startscreen-app-root #startscreen-editor-root i.subscript {
  background-image: url('../public/icons/type-subscript.svg');
}

#startscreen-app-root #startscreen-editor-root i.superscript {
  background-image: url('../public/icons/type-superscript.svg');
}

#startscreen-app-root #startscreen-editor-root i.link {
  background-image: url('../public/icons/link.svg');
}

#startscreen-app-root #startscreen-editor-root i.horizontal-rule {
  background-image: url('../public/icons/horizontal-rule.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.plus {
  background-image: url('../public/icons/plus.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.plus.biggerPlus {
  height: 28px !important;
  width: 28px !important;
}

#startscreen-app-root #startscreen-editor-root .icon.caret-right {
  background-image: url('../public/icons/caret-right-fill.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.dropdown-more {
  background-image: url('../public/icons/dropdown-more.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.font-color {
  background-image: url('../public/icons/font-color.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.font-family {
  background-image: url('../public/icons/font-family.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.bg-color {
  background-image: url('../public/icons/bg-color.svg');
}

#startscreen-app-root #startscreen-editor-root i.image {
  background-image: url('../public/icons/file-image.svg');
}

#startscreen-app-root #startscreen-editor-root i.table {
  background-image: url('../public/icons/table.svg');
}

#startscreen-app-root #startscreen-editor-root i.close {
  background-image: url('../public/icons/close.svg');
}

#startscreen-app-root #startscreen-editor-root i.figma {
  background-image: url('../public/icons/figma.svg');
}

#startscreen-app-root #startscreen-editor-root i.poll {
  background-image: url('../public/icons/card-checklist.svg');
}

#startscreen-app-root #startscreen-editor-root i.tweet {
  background-image: url('../public/icons/tweet.svg');
}

#startscreen-app-root #startscreen-editor-root i.youtube {
  background-image: url('../public/icons/youtube.svg');
}

#startscreen-app-root #startscreen-editor-root i.loom {
  background-image: url('../public/icons/loomLogo.png');
}

#startscreen-app-root #startscreen-editor-root .icon.left-align,
#startscreen-app-root #startscreen-editor-root i.left-align {
  background-image: url('../public/icons/text-left.svg');
}

#startscreen-app-root #startscreen-editor-root i.center-align {
  background-image: url('../public/icons/text-center.svg');
}

#startscreen-app-root #startscreen-editor-root i.right-align {
  background-image: url('../public/icons/text-right.svg');
}

#startscreen-app-root #startscreen-editor-root i.justify-align {
  background-image: url('../public/icons/justify.svg');
}

#startscreen-app-root #startscreen-editor-root i.indent {
  background-image: url('../public/icons/indent.svg');
}

#startscreen-app-root #startscreen-editor-root i.markdown {
  background-image: url('../public/icons/markdown.svg');
}

#startscreen-app-root #startscreen-editor-root i.outdent {
  background-image: url('../public/icons/outdent.svg');
}

#startscreen-app-root #startscreen-editor-root i.undo {
  background-image: url('../public/icons/arrow-counterclockwise.svg');
}

#startscreen-app-root #startscreen-editor-root i.redo {
  background-image: url('../public/icons/arrow-clockwise.svg');
}

#startscreen-app-root #startscreen-editor-root i.sticky {
  background-image: url('../public/icons/sticky.svg');
}

#startscreen-app-root #startscreen-editor-root i.mic {
  background-image: url('../public/icons/mic.svg');
}

#startscreen-app-root #startscreen-editor-root i.import {
  background-image: url('../public/icons/upload.svg');
}

#startscreen-app-root #startscreen-editor-root i.export {
  background-image: url('../public/icons/download.svg');
}

#startscreen-app-root #startscreen-editor-root i.diagram-2 {
  background-image: url('../public/icons/diagram-2.svg');
}

#startscreen-app-root #startscreen-editor-root i.user {
  background-image: url('../public/icons/user.svg');
}

#startscreen-app-root #startscreen-editor-root i.equation {
  background-image: url('../public/icons/plus-slash-minus.svg');
}

#startscreen-app-root #startscreen-editor-root i.gif {
  background-image: url('../public/icons/filetype-gif.svg');
}

#startscreen-app-root #startscreen-editor-root i.copy {
  background-image: url('../public/icons/copy.svg');
}

#startscreen-app-root #startscreen-editor-root i.success {
  background-image: url('../public/icons/success.svg');
}

#startscreen-app-root #startscreen-editor-root i.prettier {
  background-image: url('../public/icons/prettier.svg');
}

#startscreen-app-root #startscreen-editor-root i.prettier-error {
  background-image: url('../public/icons/prettier-error.svg');
}

#startscreen-app-root #startscreen-editor-root .link-editor .button.active,
#startscreen-app-root #startscreen-editor-root .toolbar .button.active {
  background-color: rgb(223, 232, 250);
}

#startscreen-app-root #startscreen-editor-root .link-editor .link-input {
  display: block;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

/* .link-editor div.link-edit {
  background-image: url('../public/icons/pencil-fill.svg');
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
} */

#startscreen-app-root #startscreen-editor-root .link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

#startscreen-app-root #startscreen-editor-root .link-editor .link-input a:hover {
  text-decoration: underline;
}

#startscreen-app-root #startscreen-editor-root .link-editor .font-size-wrapper,
#startscreen-app-root #startscreen-editor-root .link-editor .font-family-wrapper {
  display: flex;
  margin: 0 4px;
}

#startscreen-app-root #startscreen-editor-root .link-editor select {
  padding: 6px;
  border: none;
  background-color: rgba(0, 0, 0, 0.075);
  border-radius: 4px;
}

#startscreen-app-root #startscreen-editor-root .mention:focus {
  box-shadow: rgb(180 213 255) 0px 0px 0px 2px;
  outline: none;
}

#startscreen-app-root #startscreen-editor-root #block-controls {
  display: block;
  position: absolute;
  right: 10px;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  top: 16px;
  z-index: 10;
  border-radius: 8px;
  border: 1px solid rgb(206, 208, 212);
  overflow: hidden;
}

#startscreen-app-root #startscreen-editor-root #block-controls button {
  border: 1px solid white;
  background-color: #fff;
  display: block;
  transition: background-color 0.1s ease;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
  padding: 3px;
}

#startscreen-app-root #startscreen-editor-root #block-controls button:hover {
  background-color: #efefef;
}

#startscreen-app-root
  #startscreen-editor-root
  #block-controls
  button:focus-visible {
  border-color: blue;
}

#startscreen-app-root #startscreen-editor-root #block-controls span.block-type {
  background-size: contain;
  display: block;
  width: 18px;
  height: 18px;
  margin: 2px;
}

#startscreen-app-root
  #startscreen-editor-root
  #block-controls
  span.block-type.paragraph {
  background-image: url('../public/icons/text-paragraph.svg');
}

#startscreen-app-root #startscreen-editor-root #block-controls span.block-type.h1 {
  background-image: url('../public/icons/type-h1.svg');
}

#startscreen-app-root #startscreen-editor-root #block-controls span.block-type.h2 {
  background-image: url('../public/icons/type-h2.svg');
}

#startscreen-app-root
  #startscreen-editor-root
  #block-controls
  span.block-type.quote {
  background-image: url('../public/icons/chat-square-quote.svg');
}

#startscreen-app-root #startscreen-editor-root #block-controls span.block-type.ul {
  background-image: url('../public/icons/list-ul.svg');
}

#startscreen-app-root #startscreen-editor-root #block-controls span.block-type.ol {
  background-image: url('../public/icons/list-ol.svg');
}

#startscreen-app-root
  #startscreen-editor-root
  #block-controls
  span.block-type.code {
  background-image: url('../public/icons/code.svg');
}

#startscreen-app-root #startscreen-editor-root .characters-limit {
  color: #888;
  font-size: 12px;
  text-align: right;
  display: block;
  position: absolute;
  left: 12px;
  bottom: 5px;
}

#startscreen-app-root
  #startscreen-editor-root
  .characters-limit.characters-limit-exceeded {
  color: red;
}

#startscreen-app-root #startscreen-editor-root .dropdown {
  z-index: 9999999999999999;
  display: block;
  position: fixed;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-height: 40px;
  background-color: #fff;
}

#startscreen-app-root #startscreen-editor-root .dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  max-width: 250px;
  min-width: 100px;
}

#startscreen-app-root #startscreen-editor-root .dropdown .item.fontsize-item,
#startscreen-app-root #startscreen-editor-root .dropdown .item.fontsize-item .text {
  min-width: unset;
}

#startscreen-app-root #startscreen-editor-root .dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

#startscreen-app-root #startscreen-editor-root .dropdown .item:first-child {
  margin-top: 8px;
}

#startscreen-app-root #startscreen-editor-root .dropdown .item:last-child {
  margin-bottom: 8px;
}

#startscreen-app-root #startscreen-editor-root .dropdown .item:hover {
  background-color: #eee;
}

#startscreen-app-root #startscreen-editor-root .dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

#startscreen-app-root #startscreen-editor-root .dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

#startscreen-app-root #startscreen-editor-root .link-editor {
  position: absolute;
  z-index: 9999999999999999999;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
}

#startscreen-app-root #startscreen-editor-root .dropdown .divider {
  width: auto;
  background-color: #eee;
  margin: 4px 8px;
  height: 1px;
}

#startscreen-app-root
  #startscreen-editor-root
  span.text.dropdown-button-text.no-chev {
  padding-right: 0;
}

@media screen and (max-width: 1100px) {
  #startscreen-app-root #startscreen-editor-root .dropdown-button-text {
    display: none !important;
  }
  #startscreen-app-root #startscreen-editor-root .font-size .dropdown-button-text {
    display: flex !important;
  }
  #startscreen-app-root
    #startscreen-editor-root
    .code-language
    .dropdown-button-text {
    display: flex !important;
  }
}

#startscreen-app-root #startscreen-editor-root .icon.paragraph {
  background-image: url('../public/icons/text-paragraph.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.h1 {
  background-image: url('../public/icons/type-h1.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.h2 {
  background-image: url('../public/icons/type-h2.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.h3 {
  background-image: url('../public/icons/type-h3.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.h4 {
  background-image: url('../public/icons/type-h4.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.h5 {
  background-image: url('../public/icons/type-h5.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.h6 {
  background-image: url('../public/icons/type-h6.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.bullet-list,
#startscreen-app-root #startscreen-editor-root .icon.bullet {
  background-image: url('../public/icons/list-ul.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.check-list,
#startscreen-app-root #startscreen-editor-root .icon.check {
  background-image: url('../public/icons/square-check.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.numbered-list,
#startscreen-app-root #startscreen-editor-root .icon.number {
  background-image: url('../public/icons/list-ol.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.quote {
  background-image: url('../public/icons/chat-square-quote.svg');
}

#startscreen-app-root #startscreen-editor-root .icon.code {
  background-image: url('../public/icons/code.svg');
}

#startscreen-app-root #startscreen-editor-root .switches {
  z-index: 6;
  position: fixed;
  left: 10px;
  bottom: 70px;
  animation: slide-in 0.4s ease;
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

#startscreen-app-root #startscreen-editor-root .switch {
  display: block;
  color: #444;
  margin: 5px 0;
  background-color: rgba(238, 238, 238, 0.7);
  padding: 5px 10px;
  border-radius: 10px;
}

#startscreen-app-root #startscreen-editor-root #rich-text-switch {
  right: 0;
}

#startscreen-app-root #startscreen-editor-root #character-count-switch {
  right: 130px;
}

#startscreen-app-root #startscreen-editor-root .switch label {
  margin-right: 5px;
  line-height: 24px;
  width: 100px;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
}

#startscreen-app-root #startscreen-editor-root .switch button {
  background-color: rgb(206, 208, 212);
  height: 24px;
  box-sizing: border-box;
  border-radius: 12px;
  width: 44px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.1s;
  border: 2px solid transparent;
}

#startscreen-app-root #startscreen-editor-root .switch button:focus-visible {
  border-color: blue;
}

#startscreen-app-root #startscreen-editor-root .switch button span {
  top: 0px;
  left: 0px;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 12px;
  background-color: white;
  transition: transform 0.2s;
}

#startscreen-app-root #startscreen-editor-root .switch button[aria-checked='true'] {
  background-color: rgb(24, 119, 242);
}

#startscreen-app-root
  #startscreen-editor-root
  .switch
  button[aria-checked='true']
  span {
  transform: translateX(20px);
}

#startscreen-app-root #startscreen-editor-root .editor-shell span.editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  user-select: none;
}

#startscreen-app-root #startscreen-editor-root .editor-shell .editor-image img {
  max-width: 100%;
  cursor: default;
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  img.focused {
  /* remove dragable image */
  /* outline: 2px solid rgb(60, 132, 244);
  user-select: none; */
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  img.focused.draggable {
  /* remove dragable image */
  /* cursor: grab; */
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  img.focused.draggable:active {
  /* remove dragable image */
  /* cursor: grabbing; */
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  .image-caption-container
  .tree-view-output {
  margin: 0;
  border-radius: 0;
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  .image-caption-container {
  /* remove dragable image */
  display: none;
  /* display: block; */
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  border-top: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  min-width: 100px;
  color: #000;
  overflow: hidden;
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  .image-caption-button {
  /* remove dragable image */
  display: none;
  /* display: block; */
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 30%;
  padding: 10px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 100px;
  color: #fff;
  cursor: pointer;
  user-select: none;
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  .image-caption-button:hover {
  background-color: rgba(60, 132, 244, 0.5);
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  .image-resizer {
  /* remove dragable image */
  display: none;
  /* display: block; */
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  .image-resizer.image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  .image-resizer.image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  .image-resizer.image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  .image-resizer.image-resizer-se {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  .image-resizer.image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  .image-resizer.image-resizer-sw {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  .image-resizer.image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

#startscreen-app-root
  #startscreen-editor-root
  .editor-shell
  .editor-image
  .image-resizer.image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

#startscreen-app-root #startscreen-editor-root .emoji {
  color: transparent;
  caret-color: rgb(5, 5, 5);
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: 0 -1px;
}

#startscreen-app-root #startscreen-editor-root .emoji-inner {
  padding: 0 0.15em;
}

#startscreen-app-root #startscreen-editor-root .emoji-inner::selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

#startscreen-app-root #startscreen-editor-root .emoji-inner::moz-selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

#startscreen-app-root #startscreen-editor-root .emoji.happysmile {
  background-image: url('../public/emoji/1F642.png');
}

#startscreen-app-root #startscreen-editor-root .emoji.veryhappysmile {
  background-image: url('../public/emoji/1F600.png');
}

#startscreen-app-root #startscreen-editor-root .emoji.unhappysmile {
  background-image: url('../public/emoji/1F641.png');
}

#startscreen-app-root #startscreen-editor-root .emoji.heart {
  background-image: url('../public/emoji/2764.png');
}

#startscreen-app-root #startscreen-editor-root .keyword {
  color: rgb(241, 118, 94);
  font-weight: bold;
}

#startscreen-app-root #startscreen-editor-root .actions {
  position: absolute;
  text-align: right;
  padding: 10px;
  bottom: 0;
  right: 0;
}

#startscreen-app-root #startscreen-editor-root .actions.tree-view {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#startscreen-app-root #startscreen-editor-root .actions i {
  background-size: contain;
  display: inline-block;
  height: 15px;
  width: 15px;
  vertical-align: -0.25em;
}

#startscreen-app-root #startscreen-editor-root .actions i.indent {
  background-image: url('../public/icons/indent.svg');
}

#startscreen-app-root #startscreen-editor-root .actions i.outdent {
  background-image: url('../public/icons/outdent.svg');
}

#startscreen-app-root #startscreen-editor-root .actions i.lock {
  background-image: url('../public/icons/lock-fill.svg');
}

#startscreen-app-root #startscreen-editor-root .actions i.image {
  background-image: url('../public/icons/file-image.svg');
}

#startscreen-app-root #startscreen-editor-root .actions i.table {
  background-image: url('../public/icons/table.svg');
}

#startscreen-app-root #startscreen-editor-root .actions i.unlock {
  background-image: url('../public/icons/lock.svg');
}

#startscreen-app-root #startscreen-editor-root .actions i.left-align {
  background-image: url('../public/icons/text-left.svg');
}

#startscreen-app-root #startscreen-editor-root .actions i.center-align {
  background-image: url('../public/icons/text-center.svg');
}

#startscreen-app-root #startscreen-editor-root .actions i.right-align {
  background-image: url('../public/icons/text-right.svg');
}

#startscreen-app-root #startscreen-editor-root .actions i.justify-align {
  background-image: url('../public/icons/justify.svg');
}

#startscreen-app-root #startscreen-editor-root .actions i.disconnect {
  background-image: url('../public/icons/plug.svg');
}

#startscreen-app-root #startscreen-editor-root .actions i.connect {
  background-image: url('../public/icons/plug-fill.svg');
}

#startscreen-app-root #startscreen-editor-root .table-cell-action-button-container {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
}

#startscreen-app-root #startscreen-editor-root .table-cell-action-button {
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  position: relative;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  cursor: pointer;
}

#startscreen-app-root #startscreen-editor-root i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-image: url('../public/icons/chevron-down.svg');
}

#startscreen-app-root #startscreen-editor-root .action-button {
  background-color: #eee;
  border: 0;
  padding: 8px 12px;
  position: relative;
  margin-left: 5px;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  cursor: pointer;
}

#startscreen-app-root #startscreen-editor-root .action-button:hover {
  background-color: #ddd;
  color: #000;
}

#startscreen-app-root #startscreen-editor-root .action-button-mic.active {
  animation: mic-pulsate-color 3s infinite;
}

#startscreen-app-root #startscreen-editor-root button.action-button:disabled {
  opacity: 0.6;
  background: #eee;
  cursor: not-allowed;
}

@keyframes mic-pulsate-color {
  0% {
    background-color: #ffdcdc;
  }
  50% {
    background-color: #ff8585;
  }
  100% {
    background-color: #ffdcdc;
  }
}

#startscreen-app-root #startscreen-editor-root .debug-timetravel-panel {
  overflow: hidden;
  padding: 0 0 10px 0;
  margin: auto;
  display: flex;
}

#startscreen-app-root #startscreen-editor-root .debug-timetravel-panel-slider {
  padding: 0;
  flex: 8;
}

#startscreen-app-root #startscreen-editor-root .debug-timetravel-panel-button {
  padding: 0;
  border: 0;
  background: none;
  flex: 1;
  color: #fff;
  font-size: 12px;
}

#startscreen-app-root
  #startscreen-editor-root
  .debug-timetravel-panel-button:hover {
  text-decoration: underline;
}

#startscreen-app-root #startscreen-editor-root .debug-timetravel-button {
  border: 0;
  padding: 0;
  font-size: 12px;
  top: 10px;
  right: 15px;
  position: absolute;
  background: none;
  color: #fff;
}

#startscreen-app-root #startscreen-editor-root .debug-timetravel-button:hover {
  text-decoration: underline;
}

#startscreen-app-root #startscreen-editor-root .connecting {
  font-size: 15px;
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 10px;
  left: 10px;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
}

#startscreen-app-root #startscreen-editor-root .ltr {
  text-align: left;
}

#startscreen-app-root #startscreen-editor-root .rtl {
  text-align: right;
}

#startscreen-app-root #startscreen-editor-root .toolbar {
  /* display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
  overflow: auto;
  height: 36px; */
}

#startscreen-app-root #startscreen-editor-root .toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
}

#startscreen-app-root
  #startscreen-editor-root
  .toolbar
  button.toolbar-item:disabled {
  cursor: not-allowed;
}

#startscreen-app-root #startscreen-editor-root .toolbar button.toolbar-item.spaced {
  margin-right: 2px;
}

#startscreen-app-root
  #startscreen-editor-root
  .toolbar
  button.toolbar-item
  i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

#startscreen-app-root
  #startscreen-editor-root
  .toolbar
  button.toolbar-item:disabled
  .icon,
#startscreen-app-root
  #startscreen-editor-root
  .toolbar
  button.toolbar-item:disabled
  .text,
#startscreen-app-root
  #startscreen-editor-root
  .toolbar
  button.toolbar-item:disabled
  i.format,
#startscreen-app-root
  #startscreen-editor-root
  .toolbar
  button.toolbar-item:disabled
  .chevron-down {
  opacity: 0.2;
}

#startscreen-app-root #startscreen-editor-root .toolbar button.toolbar-item.active {
  background-color: rgba(223, 232, 250, 0.3);
}

#startscreen-app-root
  #startscreen-editor-root
  .toolbar
  button.toolbar-item.active
  i {
  opacity: 1;
}

#startscreen-app-root
  #startscreen-editor-root
  .toolbar
  .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

#startscreen-app-root
  #startscreen-editor-root
  .toolbar
  .toolbar-item.font-family
  .text {
  display: block;
  max-width: 40px;
}

#startscreen-app-root #startscreen-editor-root .toolbar .code-language {
  width: 150px;
}

#startscreen-app-root #startscreen-editor-root .toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
  text-align: left;
  padding-right: 10px;
}

#startscreen-app-root #startscreen-editor-root .toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

#startscreen-app-root
  #startscreen-editor-root
  .toolbar
  .toolbar-item
  .icon.iconOnly {
  margin-right: 0;
}

#startscreen-app-root #startscreen-editor-root .toolbar i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}

#startscreen-app-root #startscreen-editor-root .toolbar i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}

#startscreen-app-root #startscreen-editor-root .toolbar .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

#startscreen-app-root #startscreen-editor-root .sticky-note-container {
  position: absolute;
  z-index: 9;
  width: 120px;
  display: inline-block;
}

#startscreen-app-root #startscreen-editor-root .sticky-note {
  line-height: 1;
  text-align: left;
  width: 120px;
  margin: 25px;
  padding: 20px 10px;
  position: relative;
  border: 1px solid #e8e8e8;
  font-family: 'Reenie Beanie';
  font-size: 24px;
  border-bottom-right-radius: 60px 5px;
  display: block;
  cursor: move;
}

#startscreen-app-root #startscreen-editor-root .sticky-note:after {
  content: '';
  position: absolute;
  z-index: -1;
  right: -0px;
  bottom: 20px;
  width: 120px;
  height: 25px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 2px 15px 5px rgba(0, 0, 0, 0.4);
  transform: matrix(-1, -0.1, 0, 1, 0, 0);
}

#startscreen-app-root #startscreen-editor-root .sticky-note.yellow {
  border-top: 1px solid #fdfd86;
  background: linear-gradient(
    135deg,
    #ffff88 81%,
    #ffff88 82%,
    #ffff88 82%,
    #ffffc6 100%
  );
}

#startscreen-app-root #startscreen-editor-root .sticky-note.pink {
  border-top: 1px solid #e7d1e4;
  background: linear-gradient(
    135deg,
    #f7cbe8 81%,
    #f7cbe8 82%,
    #f7cbe8 82%,
    #e7bfe1 100%
  );
}

#startscreen-app-root #startscreen-editor-root .sticky-note-container.dragging {
  transition: none !important;
}

#startscreen-app-root #startscreen-editor-root .sticky-note div {
  cursor: text;
}

#startscreen-app-root #startscreen-editor-root .sticky-note .delete {
  border: 0;
  background: none;
  position: absolute;
  top: 8px;
  right: 10px;
  font-size: 10px;
  cursor: pointer;
  opacity: 0.5;
}

#startscreen-app-root #startscreen-editor-root .sticky-note .delete:hover {
  font-weight: bold;
  opacity: 1;
}

#startscreen-app-root #startscreen-editor-root .sticky-note .color {
  border: 0;
  background: none;
  position: absolute;
  top: 8px;
  right: 25px;
  cursor: pointer;
  opacity: 0.5;
}

#startscreen-app-root #startscreen-editor-root .sticky-note .color:hover {
  opacity: 1;
}

#startscreen-app-root #startscreen-editor-root .sticky-note .color i {
  display: block;
  width: 12px;
  height: 12px;
  background-size: contain;
}

#startscreen-app-root #startscreen-editor-root .excalidraw-button {
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

#startscreen-app-root #startscreen-editor-root .excalidraw-button.selected {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

#startscreen-app-root #startscreen-editor-root .github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}
@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}
@media (max-width: 500px) {
  #startscreen-app-root #startscreen-editor-root .github-corner:hover .octo-arm {
    animation: none;
  }
  #startscreen-app-root #startscreen-editor-root .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}

#startscreen-app-root #startscreen-editor-root .spacer {
  letter-spacing: -2px;
}

#startscreen-app-root #startscreen-editor-root button.item i {
  opacity: 0.6;
}

#startscreen-app-root #startscreen-editor-root button.item.dropdown-item-active {
  background-color: rgba(223, 232, 250, 0.3);
}

#startscreen-app-root #startscreen-editor-root button.item.dropdown-item-active i {
  opacity: 1;
}

#startscreen-app-root #startscreen-editor-root hr {
  padding: 2px 2px;
  border: none;
  margin: 1em 0;
  cursor: pointer;
}

#startscreen-app-root #startscreen-editor-root hr:after {
  content: '';
  display: block;
  height: 2px;
  background-color: #ccc;
  line-height: 2px;
}

#startscreen-app-root #startscreen-editor-root hr.selected {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

#startscreen-app-root #startscreen-editor-root .TableNode__contentEditable {
  min-height: 20px;
  border: 0px;
  resize: none;
  cursor: text;
  display: block;
  position: relative;
  tab-size: 1;
  outline: 0px;
  padding: 0;
  user-select: text;
  font-size: 15px;
  white-space: pre-wrap;
  word-break: break-word;
  z-index: 3;
}
